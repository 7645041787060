import {get} from "../request";
import contentList from "../apiList/contentList";

const Index = (id = 0, limit = 50) => {
    return get(contentList.Index, {id: id, limit: limit});
};

const List = (classificationId, id = 0, limit = 50) => {
    return get(contentList.List, { classificationId: classificationId, id: id, limit: limit })
}

const Info = (id) => {
    return get(contentList.Info, { id: id });
}

export default {
    Index,
    List,
    Info,
};
